import $ from "jquery";
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
//import 'slick-carousel/slick/slick';
import '../js/menu';
import './youtube'
import loadTemplate from "../assets/js/main";

const mainSlider =  ()  => {
	var BasicSlider = $('.slider-active');
	BasicSlider.on('init', function (e, slick) {
		var $firstAnimatingElements = $('.single-slider:first-child').find('[data-animation]');
		doAnimations($firstAnimatingElements);
	});
	BasicSlider.on('beforeChange', function (e, slick, currentSlide, nextSlide) {
		var $animatingElements = $('.single-slider[data-slick-index="' + nextSlide + '"]').find('[data-animation]');
		doAnimations($animatingElements);
	});
	BasicSlider.not('.slick-initialized').slick({
		autoplay: true,
		autoplaySpeed: 10000,
		dots:false ,
		fade: true,
		arrows: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="far fa-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="far fa-angle-right"></i></button>',
		responsive: [
			{ 
				breakpoint: 767, 
				settings: { 
					dots: false, 
					arrows: false,
					slidesToShow: 1 
				} 
			}
		]
	});

	function doAnimations(elements) {
		var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
		elements.each(function () {
			var $this = $(this);
			var $animationDelay = $this.data('delay');
			var $animationType = 'animated ' + $this.data('animation');
			$this.css({
				'animation-delay': $animationDelay,
				'-webkit-animation-delay': $animationDelay
			});
			$this.addClass($animationType).one(animationEndEvents, function () {
				$this.removeClass($animationType);
			});
		});
	}
}

function init(){
	loadTemplate();
	mainSlider();	
	$('#loading').delay(0).fadeOut('fast');
	$('.muteToggle').on('click', function()	{		
		const video=$('.video-bg');
		console.log(video[0].muted)
		if (video[0].muted) {
		  video[0].muted = false;
		  $(this).text('Mute');
		} else {
		  video[0].muted = true;
		  $(this).text('Unmute');
		}
	});
	$('#mobile-menu').meanmenu({
		meanMenuContainer: '.mobile-menu',
		meanScreenWidth: "992"
	});
	new Swiper(".testimonial-slider", {
		modules: [Navigation, Pagination],
		loop: true,
		spaceBetween: 30,
		speed: 400,
		slidesPerView: 2,
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		},
		autoplay: {
			enabled: true,
			delay: 6000
		},
		navigation: {
			nextEl: '.xb-swiper-arrow-next',
			prevEl: '.xb-swiper-arrow-prev',
		},
		breakpoints: {
			'1600': {
				slidesPerView: 2,
			},
			'768': {
				slidesPerView: 2,
			},
			'576': {
				slidesPerView: 1,
			},
			'0': {
				slidesPerView: 1,
			},
		},
	});
	$('.popup-video').simpleLightboxVideo();
	$('.testimonial-active').not('.slck-initiailized').slick({
		dots: true,
		infinite: true,
		arrows: false,
		speed: 1000,
		slidesToShow:3,
		slidesToScroll: 2,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 2,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});

	new Swiper('.tp-brand-active', {
		slidesPerView: 5,
		loop: true,
		dots: true,
		autoplay: {
			delay: 4000,
		},
		breakpoints: {
			'1200': {
				slidesPerView: 4,
			},
			'992': {
				slidesPerView: 3,
			},
			'768': {
				slidesPerView: 2,
			},
			'576': {
				slidesPerView: 2,
			},
			'0': {
				slidesPerView: 1,
			},
		},
	});

	new Swiper('.tp-social-active', {
		slidesPerView: 6,
		loop: true,
		dots: true,
		autoplay: {
			delay: 4000,
		},
		breakpoints: {
			'1400': {
				slidesPerView: 6,
			},
			'1200': {
				slidesPerView: 5,
			},
			'992': {
				slidesPerView: 4,
			},
			'768': {
				slidesPerView: 3,
			},
			'576': {
				slidesPerView: 2,
			},
			'0': {
				slidesPerView: 1,
			},
		},
	});
}
export default init;