import React from "react";
import HeaderMenu from "./HeaderMenu";
import { useState, useEffect } from "react";
import getBaseUrl from "../../base/htttp";
import 'szmigiel-meanmenu/jquery.meanmenu.min'

import phIcon from '../../assets/img/icon1/phone-2.svg';
import Topbar from "./Topbar";

function Header1() {
  const [topbar, setTopbar] = useState([]);
  const [ls, setLs] = useState([]);
  const [cs, setCs] = useState([]);
  const [scrolled, setScrolled] = React.useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
    }
    else {
      setScrolled(false);
    }
  }
  useEffect(() => {
    fetch(getBaseUrl() + "topbar", { method: 'POST' })
      .then((response) => response.json())
      .then((result) => {
        setTopbar(result.data.content);
        setLs(result.data.ls);
        setCs(result.data.cs);
      });
  }, []);

  return (
    <>
      <header>
        <div className="tp-header-area tp-header-border">
          <Topbar htmldata={topbar} />
          <div id="header-sticky" className="tp-header-bottom white-bg tp-header-height">
            <div className="container container-large">
              <div className="tp-header-box">
                <div className="row align-items-center">
                  <div className="col-xl-2 col-lg-4 col-md-3 col-8">
                    <div className="logo">
                      <a href="/">
                        <img data-width="145" width="145" src={ls.logo_url} alt={ls.site_title} />
                      </a>
                    </div>
                  </div>
                  <div className="col-xl-10 col-lg-8 col-md-9 col-4">
                    <div className="tp-header-main d-flex align-items-center justify-content-xl-between justify-content-end">
                      <div className="main-menu d-none d-xl-block">
                        <HeaderMenu />
                      </div>
                      <div className="d-none d-md-block">
                        <div className="tp-header-right d-flex align-items-center justify-content-end">
                          <div className="tp-header-call d-flex align-items-center">
                            <div className="tp-header-call-icon">
                              <span><img src={phIcon} alt={ls.site_title} /></span>
                            </div>
                            <div className="tp-header-call-content">
                              <span>Phone:</span>
                              <a href="tel:1300121634">1300 121 634 </a>
                            </div>
                          </div>
                          <div className="tp-header-btn ml-10 d-none d-lg-block">
                            <a href="contact.html" data-bs-toggle="modal" data-bs-target="#staticBackdrop">BOOK ONLINE <i className="fa-regular fa-arrow-right-long"></i></a>
                            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="staticBackdropLabel">Get Started Now</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                  </div>
                                  <div className="modal-body">
                                    Let's get started by entering your Post Code.
                                  </div>
                                  <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="offcanvas-btn d-xl-none ml-30">
                        <button className="offcanvas-open-btn"><i className="fa-solid fa-bars"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="offcanvas__area">
        <div className="offcanvas__wrapper">
          <div className="offcanvas__close">
            <button className="offcanvas__close-btn offcanvas-close-btn">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 1L1 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M1 1L11 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </button>
          </div>
          <div className="offcanvas__content">
            <div className="offcanvas__top mb-70 d-flex justify-content-between align-items-center">
              <div className="offcanvas__logo logo">
                <a href="/">
                  <img src={ls.logo_url} alt={ls.site_title} />
                </a>
              </div>
            </div>
            <div className="tp-main-menu-mobile"></div>
            <div className="offcanvas__btn">
              <a href="contact.html" className="tp-btn">Getting Started <i
                className="fa-regular fa-chevron-right"></i></a>
            </div>
            <div className="side-info-contact">
              <span>we are here</span>
              <p>1489 Langley Ave <br /> Grand Forks Afb, North.</p>
            </div>
            <div className="side-info-social">
              <a href="#"><i className="fa-brands fa-facebook-f"></i></a>
              <a href="#"><i className="fa-brands fa-twitter"></i></a>
              <a href="#"><i className="fa-brands fa-pinterest-p"></i></a>
              <a href="#"><i className="fa-solid fa-paper-plane"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div className="body-overlay"></div>
    </>
  )
}

export default Header1;