import * as DOMPurify from 'dompurify';

const Sidebar = ({ htmldata }) => {
    return (
        <div class="col-md-4">
            <aside className='tp-sidebar-wrapper mb-60"' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(htmldata)}}></aside>
        </div>
    );
};

export default Sidebar;