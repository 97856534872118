const Breadcrumb = ({ data, image }) => {
    const headerStyle = {
        backgroundColor: "#607D8B",
        backgroundImage: `url(${image})`,
        backgroundSize: "cover"
    }
    return (
        <section className="breadcrumb-area tp-breadcrumb-bg breadcrumb-wrap" style={headerStyle}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="tp-breadcrumb text-center">
                            <div className="tp-breadcrumb-link mb-10">
                                <span className="tp-breadcrumb-link-active"><a href="index.html">Home</a></span>
                                <span> \ {data.title}</span>
                            </div>
                            <h2 className="tp-breadcrumb-title">{data.title}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Breadcrumb;