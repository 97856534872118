import React from "react";
import * as DOMPurify from 'dompurify';

const Topbar= ({ htmldata }) => {
    const sanitizedContent = DOMPurify.sanitize(htmldata, {
        ADD_TAGS: ['iframe'],
        ADD_ATTR: ['src','target','rel'],        
      });

    return (  
        <div className="topbar" dangerouslySetInnerHTML={{__html: sanitizedContent}}></div>        
    );
}
export default Topbar;