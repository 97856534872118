import React, { useState, useEffect } from 'react';
import * as DOMPurify from 'dompurify';

import Header1 from '../Layout/Header1';
import Footer from '../Layout/Footer';
import getBaseUrl from '../../base/htttp';
import init from '../../js/main';
function Index() {    
	const [data, setData] = useState("Loading...");

	useEffect(() => {
		const getData = async ()  => {
			await fetch(getBaseUrl()+"page")        
				.then(async (response) => await response.json())
				.then(async (result) => {
					setData(result.data);
							
			});	
			 setTimeout(function() {
				init();
			 }.bind(this), 400);						
		}

		getData();		
	}, []);
	
	
	return (        
		<div>			
            <Header1 />			
			<div className="main" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.content)}}></div>
			<Footer />
		</div>
	);
}

export default Index;