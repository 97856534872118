import Content from "./Content";

const PageWithSideBar = ({ content }) => {
    return (
        <div className="col-md-8">
            <Content htmldata={content} />
        </div>
    );
};

export default PageWithSideBar;